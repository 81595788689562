import { Model, Attribute, HasMany, BelongsTo } from './decorators';
import { BaseApiModel } from './base';
import { ActiveStorageAttachmentType, MeType, RoleType } from '@parashift/shared/types';
import { ActiveStorageAttachment } from './active-storage-attachment.model';
import { Role } from './role.model';
import { AllPermissions } from '@parashift/shared/constants';

export interface OnboardingCompany {
  company_name: string;
  company_role: string;
  company_type: string;
  phone_number: string;
  dont_call_me: boolean;
  validation: boolean;
}

export interface OnboardingUseCase {
  evaluation_for: string;
  number_of_use_cases: string;
  number_of_documents: string;
  priority: string;
}

export enum OnboardingStatus {
  company = 'company',
  use_case = 'use_case',
  upload = 'upload',
  complete = 'complete'
}

export class CompanyType {
  static readonly sme = $localize `:@@constants.company_type.sme:SME`;
  static readonly enterprise = $localize `:@@constants.company_type.enterprise:Enterprise`;
  static readonly consulting_or_integrator = $localize `:@@constants.company_type.consulting_or_integrator:Consulting / Integrator`;
  static readonly other = $localize `:@@constants.company_type.other:Other`;
}

export class CompanyRole {
  static readonly c_level = $localize `:@@constants.company_role.c_level:C-Level`;
  static readonly business_analyst = $localize `:@@constants.company_role.business_analyst:Business Analyst`;
  static readonly researcher = $localize `:@@constants.company_role.researcher:Researcher`;
  static readonly bpo_operator = $localize `:@@constants.company_role.bpo_operator:BPO Operator`;
  static readonly private = $localize `:@@constants.company_role.private:Private`;
}

export class EvaluationSubject {
  static readonly self = $localize `:@@constants.evaluation_subject.self:Use in my company`;
  static readonly client = $localize `:@@constants.evaluation_subject.client:Use for a client`;
}

export class NumberOfUseCases {
  static readonly '0-5' = '0-5';
  static readonly '6-15' = '6-15';
  static readonly '16-500' = '16-500';
}

export class NumberOfDocuments {
  static readonly '< 5 000' = '< 5 000';
  static readonly '< 50 000' = '< 50 000';
  static readonly '< 500 000' = '< 500 000';
  static readonly '> 500 000' = '> 500 000';
}

export class TimePriority {
  static readonly immediately = $localize `:@@constants.time_priority.immediately:Immediately`;
  static readonly next_2_months = $localize `:@@constants.time_priority.next_2_months:Next 2 months`;
  static readonly next_12_months = $localize `:@@constants.time_priority.next_12_months:Next 12 months`;
  static readonly dont_know = $localize `:@@constants.time_priority.dont_know:I don't know`;
}

export type TenantOnboarding = Partial<OnboardingCompany> & Partial<OnboardingUseCase> & {
  status: OnboardingStatus,
  hide_next_steps?: boolean
};

export interface Onboarding {
  [tenant_id: number]: TenantOnboarding;
}

export interface ResetOptions {
  filters?: ResetConfig;
}

export interface ResetConfig {
  done: boolean;
  version: string;
}

export interface TenantMembership {
    id: number;
    disabled: boolean;
    name: string;
    role: string;
    permissions: AllPermissions[];
    annotation_tenant: any;
    annotation_tenant_id: number;
    super_tenant_id: number;
    allowed_ip_addresses: string[];
}

const GlobalRoles = {
  admin: $localize `:@@common.admin:Admin` as 'admin',
  billing: $localize `:@@common.billing:Billing` as 'billing',
  employee: $localize `:@@common.employer:Employee` as 'employee',
  engineering: $localize `:@@common.engineering:Engineering` as 'engineering',
  sales: $localize `:@@common.sales:Sales` as 'sales',
  support: $localize `:@@common.support:Support` as 'support',
};
type GlobalRoles = (typeof GlobalRoles)[keyof typeof GlobalRoles];
export { GlobalRoles };

export interface MePlainModel {
  id: string;
  api_key: boolean;
  created_at: Date;
  current_password: string;
  disabled: boolean;
  email: string;
  global_roles: GlobalRoles[];
  language: string;
  last_used_tenant_id: number;
  name: string;
  password: string;
  refresh_token: string;
  settings: {
    onboarding?: Onboarding,
    reset?: ResetOptions;
    [key: string]: any
  };
  tenants: TenantMembership[];
  unconfirmed_email: any;
  updated_at: string;
  user: boolean;
  confirmed_at: Date;
  two_factor_enabled: boolean;

  roles: (Role['plainModel'] | Partial<Role['plainModel']>)[];
  avatar_attachment: ActiveStorageAttachment['plainModel'] | Partial<ActiveStorageAttachment['plainModel']>;

}

@Model({ type: MeType })
export class Me extends BaseApiModel<MePlainModel> {

  @Attribute()
  api_key: boolean;

  @Attribute({ readonly: true })
  created_at: Date;

  @Attribute()
  current_password: string;

  @Attribute()
  disabled: boolean;

  @Attribute()
  email: string;

  @Attribute()
  global_roles: GlobalRoles[];

  @Attribute()
  language: string;

  @Attribute()
  last_used_tenant_id: number;

  @Attribute()
  name: string;

  @Attribute()
  password: string;

  @Attribute()
  refresh_token: string;

  @Attribute()
  settings: {
    onboarding?: Onboarding,
    reset?: ResetOptions;
    [key: string]: any
  };

  @Attribute()
  tenants: TenantMembership[];

  @Attribute()
  unconfirmed_email: any;

  @Attribute({ readonly: true })
  updated_at: string;

  @Attribute()
  user: boolean;

  @Attribute()
  confirmed_at: Date;

  @Attribute()
  two_factor_enabled: boolean;

  // Includes / Relations

  @HasMany({ class: RoleType, sidepostable: true })
  roles: Role[];

  @BelongsTo({ class: ActiveStorageAttachmentType, sidepostable: true })
  avatar_attachment: (ActiveStorageAttachment | Partial<ActiveStorageAttachment>);

  get plainModel(): MePlainModel {
    const model = this.toHash() as MePlainModel;
    model.language = this.language || 'en';
    return model;
  }

  getTenantOnboarding(tenant_id: number) {
    if (!this.settings) {
      this.settings = {};
    }
    if (!this.settings.onboarding) {
      this.settings.onboarding = {};
    }
    let onboarding = this.settings.onboarding[tenant_id];
    if (!onboarding) {
      this.settings.onboarding[tenant_id] = onboarding = { status: OnboardingStatus.company };
    }
    return onboarding;
  }

  setTenantOnboarding(tenant_id: number, onboarding: TenantOnboarding) {
    // only this way of immutable update results in .hasDirtyAttributes = true
    this.settings = {
      ...this.settings,
      onboarding: {
        ...this.settings.onboarding,
        [tenant_id]: onboarding
      }
    };
  }
}
